import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import languageReducer from './slices/language'
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['language']
};

const rootReducer = combineReducers({
  user: userReducer,
  language: languageReducer
});

export { rootPersistConfig, rootReducer };
