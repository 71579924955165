import { PERMISSIONS } from '../../guards/Permissions';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);
const role = window.localStorage.getItem('role');

const ICONS = {
  user: getIcon('ic_user'),
  surcussale: getIcon('ic_surcussale'),
  transac: getIcon('transac'),
  analytics: getIcon('ic_analytics'),
  borlette: getIcon('ic_borlette'),
  limite: getIcon('ic_limite'),
  tirage: getIcon('ic_tirage'),
  rapport: getIcon('ic_rapport'),
  supervisor: getIcon('ic_supervisor'),
  agent: getIcon('ic_agent'),
  tchala:getIcon('ic_boule'),
  delete:getIcon('ic_delete'),
  langue:getIcon('ic_langue')

};

export const GetSidebarConfig = (t) => {

  return [
  // ----------------------------------------------------------------------
  {
    items: [
      // MANAGEMENT : BOLETTE
      {
        title:  t('sidebar.dashboard'),
        path: PATH_DASHBOARD.general?.app,
        icon: ICONS.limite
      },
      // MANAGEMENT : TIRAGE
      {
        title: t('sidebar.configuration'),
        path: PATH_DASHBOARD.tirage?.configuration,
        icon: ICONS.tirage,
        children: [
          { title: t('sidebar.tirages'), path: PATH_DASHBOARD.tirage?.info_tirage, perm: 'view_infotirage' },
          ...(role === 'superuser'
            ? [{ title: t('sidebar.prime_generale'), path: PATH_DASHBOARD.tirage?.configuration_list, perm: 'view_tauxtirage' }]
            : [{ title: t('sidebar.prime_generale'), path: PATH_DASHBOARD.tirage?.configuration, perm: 'view_tauxtirage' }]),
          {
            title: t('sidebar.prime_agent'),
            path: PATH_DASHBOARD.tirage?.configuration_type('agent'),
            perm: 'view_agenttauxtirage'
          },
          {
            title: t('sidebar.prime_tirage'),
            path: PATH_DASHBOARD.tirage?.configuration_type('tirage'),
            perm: 'view_tiragetauxtirage'
          },
          {
            title: t('sidebar.prime_succursale'),
            path: PATH_DASHBOARD.tirage?.configuration_type('surcussale'),
            perm: 'view_surcussaletauxtirage'
          },
          {
            title: t('sidebar.superviseur'),
            path: PATH_DASHBOARD.supervisor?.root,
            perm: 'view_supervisor'
          },

          {
            title: t('sidebar.nombre_mariage_agent'),
            path: PATH_DASHBOARD.mariage?.root,
            perm: 'view_supervisor'
          },
          {
            title: t('sidebar.nombre_mariage_surc'),
            path: PATH_DASHBOARD.mariageSurcussale?.root,
            perm: 'view_supervisor'
          },
          {
            title: t('sidebar.prime_mariage_agent'),
            path: PATH_DASHBOARD.amountmariageAgent?.root,
            perm: 'view_supervisor'
          },
          {
            title: t('sidebar.prime_mariage_surcussale'),
            path: PATH_DASHBOARD.amountmariageSurcussale?.root,
            perm: 'view_supervisor'
          },
          
          {
            title:  t('sidebar.message'),
            path: PATH_DASHBOARD.user?.promote,
            perm: 'view_apppromote'
          },
          {
            title: t('sidebar.pays'),
            path: PATH_DASHBOARD.user?.country,
            perm: 'view_country'
          }
        ]
      },
      // MANAGEMENT : RAPPORT

      ...(role === 'superuser'
        ? [
            {
              title: t('sidebar.company'),
              path: PATH_DASHBOARD.company?.root,
              icon: ICONS.surcussale,
              perm: 'view_company',
              children: [
                { title: t('sidebar.ajouter'), path: PATH_DASHBOARD.company?.newCompany, perm: 'add_company' },
                { title:  t('sidebar.liste'), path: PATH_DASHBOARD.company?.list, perm: 'view_company' },
                { title:  t('sidebar.list_opt'), path: PATH_DASHBOARD.borlette?.lotto, perm: 'view_lotto' },
                { title: t('sidebar.comp_pay'), path: PATH_DASHBOARD.company?.companyPayment, perm: 'view_payment' },
                { title:  t('sidebar.comp_stat'), path: PATH_DASHBOARD.company?.companyStatus, perm: 'view_payment' }
              ]
            }
          ]
        : []),
      {
        title: t('sidebar.succursale'),
        path: PATH_DASHBOARD.generale.root,
        icon: ICONS.surcussale,
        perm: 'view_generale',
        children: [
          { title: t('sidebar.ajouter'), path: PATH_DASHBOARD.generale?.newGenerale, perm: 'add_generale' },
          { title: t('sidebar.liste'), path: PATH_DASHBOARD.generale?.list, perm: 'view_generale' }
        ]
      },
      {
        title: t('sidebar.vendor'),
        path: PATH_DASHBOARD.agent?.root,
        icon: ICONS.agent,
        perm: 'view_agent',
        children: [
          { title: t('sidebar.ajouter'), path: PATH_DASHBOARD.agent?.newAgent, perm: 'add_agent' },
          { title: t('sidebar.liste'), path: PATH_DASHBOARD.agent?.list, perm: 'view_agent' }
        ]
      },
      {
        title: t('sidebar.surveillance'),
        path: PATH_DASHBOARD.borlette?.statistique,
        icon: ICONS.borlette,
        children: [
          { title: t('sidebar.lot_gagn'), path: PATH_DASHBOARD.tirage?.list, perm: 'view_tirage' },
          { title: t('sidebar.lot_gagn_auto'), path: PATH_DASHBOARD.tirage?.newTirageAuto, perm: 'add_tirage_auto' },
          { title: t('sidebar.lot_gagn_status'), path: PATH_DASHBOARD.tirageStatus?.list,perm: 'add_tirage_auto' },
          { title: t('sidebar.lot_gagn_status_comp'), path: PATH_DASHBOARD.tirageStatus2?.list,perm: 'view_tirage' },
          { title: t('sidebar.block_boul'), path: PATH_DASHBOARD.borlette?.blocage_boule, perm: 'view_bouleblock' },
          { title: t('sidebar.statistic'), path: PATH_DASHBOARD.borlette?.statistique, perm: PERMISSIONS.VIEW_STATISTIQUE },
          { title: t('sidebar.list_opt'), path: PATH_DASHBOARD.borlette?.lotto, perm: 'view_lotto' },
          ...(role === 'superuser'
            ? [{ title: t('sidebar.limit_boul100'), path: PATH_DASHBOARD.limits?.limit_ab_list, perm: 'view_limiteallboules' }]
            : [{ title: t('sidebar.limit_boul100'), path: PATH_DASHBOARD.limits?.limit_ab, perm: 'view_limiteallboules' }]),
          ...(role === 'superuser'
            ? [
                {
                  title: t('sidebar.limit_boul_pair'),
                  path: PATH_DASHBOARD.limits?.limit_bp_list,
                  perm: 'view_limiteboulepaire'
                }
              ]
            : [
                { title: t('sidebar.limit_boul_pair'), path: PATH_DASHBOARD.limits?.limit_bp, perm: 'view_limiteboulepaire' }
              ]),
          ...(role === 'superuser'
            ? [
                {
                  title: t('sidebar.limit_boul_point'),
                  path: PATH_DASHBOARD.limits?.limit_bpt_list,
                  perm: 'view_limitepointsboules'
                }
              ]
            : [
                {
                  title: t('sidebar.limit_boul_point'),
                  path: PATH_DASHBOARD.limits?.limit_bpt,
                  perm: 'view_limitepointsboules'
                }
              ]),
          { title:  t('sidebar.limit_jeu'), path: PATH_DASHBOARD.configuration_borlette?.type_game, perm: 'view_typegame' },
          {
            title: t('sidebar.limit_jeu_ag'),
            path: PATH_DASHBOARD.configuration_borlette?.type_game_agent,
            perm: 'view_typegameagent'
          },
          { title: t('sidebar.limit_boul'), path: PATH_DASHBOARD.configuration_borlette?.type_boule, perm: 'view_typeboule' },
          {
            title: t('sidebar.limit_boul_ag'),
            path: PATH_DASHBOARD.configuration_borlette?.type_boule_agent,
            perm: 'view_typebouleagent'
          },
          {
            title: t('sidebar.limit_interval'),
            path: PATH_DASHBOARD.configuration_borlette?.limit_intervalle,
            perm: 'view_intervalleoption'
          },
          { title: t('sidebar.trace_ag'), path: PATH_DASHBOARD.user?.tracabilite_agent, perm: 'view_traceagentaction' },
          {
            title: t('sidebar.trace_master_vendor'),
            path: PATH_DASHBOARD.user?.tracabilite_supervisor,
            perm: 'view_tracesupervisoraction'
          },
          ...(role === 'superuser'
            ? [
                {
                  title: t('sidebar.trace_comp'),
                  path: PATH_DASHBOARD.user?.tracabilite_company,
                  perm: 'view_tracecompanyaction'
                }
              ]
            : [])
          // { title: 'Rapports Journalier', path: PATH_DASHBOARD.rapport?.journalier }
        ]
      },

      // MANAGEMENT : SUPERVISOR
      {
        title: t('sidebar.tchala'),
        path: PATH_DASHBOARD.tchala?.root,
        icon: ICONS.tchala,
       
        children: [
          ...(role ==="superuser" ? [
            { title: t('sidebar.add_boul'), path: PATH_DASHBOARD.tchala?.new },
            { title: t('sidebar.list_boul'), path: PATH_DASHBOARD.tchala?.list }
          ]
        : [
          { title: t('sidebar.list_boul'), path: PATH_DASHBOARD.tchala?.list }
          ]),
          
        ]
      },
      {
        title: t('sidebar.del_fiche'),
        path: PATH_DASHBOARD.delete_fiche?.root,
        icon: ICONS.delete,
       
        children: [
          
          { title: t('sidebar.do_it'), path: PATH_DASHBOARD.delete_fiche?.list,perm: 'view_payment' }
        ,
          
        ]
      },
      // MANAGEMENT : SUCCURSALE
      {
        title: t('sidebar.succursale'),
        path: PATH_DASHBOARD.surcussale?.root,
        icon: ICONS.surcussale,
        perm: 'view_surcussale',
        children: [
          { title: t('sidebar.ajouter'), path: PATH_DASHBOARD.surcussale?.newSurcussale, perm: 'add_surcussale' },
          { title: t('sidebar.liste'), path: PATH_DASHBOARD.surcussale?.list, perm: 'view_surcussale' }
        ]
      },

      // MANAGEMENT : AGENT
      {
        title: t('sidebar.report'),
        path: PATH_DASHBOARD.rapport?.root,
        icon: ICONS.analytics,
        children: [
          { title: t('sidebar.vente'), path: PATH_DASHBOARD.rapport?.root, perm: PERMISSIONS.VIEW_REPORT_VENTE },
          { title: t('sidebar.fich_cour_tirage'), path: PATH_DASHBOARD.rapport?.avantTirage, perm: PERMISSIONS.VIEW_REPORT_VENTE },
          
          { title: t('sidebar.fich_fin_tirage'), path: PATH_DASHBOARD.rapport?.tirage, perm: PERMISSIONS.VIEW_REPORT_VENTE },
          { title: t('sidebar.cntrl_ag'), path: PATH_DASHBOARD.rapport?.agent, perm: PERMISSIONS.VIEW_REPORT_AGENT },
          { title: t('sidebar.fich_vent'), path: PATH_DASHBOARD.borlette?.list, perm: 'view_fichevente' },
          { title: t('sidebar.fich_gagn'), path: PATH_DASHBOARD.borlette?.list_fgagnant, perm: PERMISSIONS.VIEW_FICHE_WIN },
          { title: t('sidebar.fich_delete'), path: PATH_DASHBOARD.borlette?.list_fsupp, perm: PERMISSIONS.VIEW_FICHE_DELETE }
          // { title: 'Rapports Journalier', path: PATH_DASHBOARD.rapport?.journalier }
        ]
      },

      {
        title: t('sidebar.transaction'),
        path: PATH_DASHBOARD.transaction.root,
        icon: ICONS.transac,
        perm: 'view_wallettransaction'
      },
      {
        title: t('sidebar.generat_comp'),
        path: PATH_DASHBOARD.user?.generateCompany,
        icon: ICONS.surcussale,
        perm: 'add_company'
      },
      {
        title: t('sidebar.facture'),
        path: PATH_DASHBOARD.company.companyFactu,
        icon: ICONS.transac
      },
      {
        title: t('sidebar.langue'),
        path: PATH_DASHBOARD.company.langue,
        icon: ICONS.langue
      },

      // MANAGEMENT : COMPANY
      ...(role !== 'superuser'
        ? [
            {
              title: t('sidebar.account'),
              icon: ICONS.user,
              path: PATH_DASHBOARD.user?.root,
              children: [
                { title: t('sidebar.edit_account'), path: PATH_DASHBOARD.user?.account },
                {
                  title: t('sidebar.trace_comp'),
                  path: PATH_DASHBOARD.user?.tracabilite_company,
                  perm: 'view_tracecompanyaction'
                },
                { title:  t('sidebar.historik_paid'), path: PATH_DASHBOARD.company?.companyPayment, perm: 'view_payment' },
                { title: t('sidebar.generate_x'), path: PATH_DASHBOARD.user?.generateAgent, perm: 'add_agent' }
              ]
            }
          ]
        : [])
    ]
  }
];

}
