// src/components/LanguageSync.js
import { useEffect } from 'react';
import { useDispatch, useSelector } from '../redux/store';
import { setLanguage } from '../redux/slices/language';
import i18n from '../locales/i18n';

export default function LanguageSync() {
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((state) => state.language);

  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng') || 'fr';
    console.log('li propageee',lang)
    // Change i18n language si différent
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }

    // Si Redux différent → synchro
    if (lang !== currentLanguage) {
      dispatch(setLanguage(lang));
    }
  }, [dispatch, currentLanguage]);

  return null; // Ce composant n'affiche rien
}
